.Page {
  padding-top: 64px;
  min-height: calc(100vh - 64px - 80px);
  &__header {
    background-color: #fff;
    padding: 22px 16px 4px;
    &__heading {
      align-items: baseline;
      &__title {
        .Icon__tokenIcon {
          width: 1.3333em;
          vertical-align: -0.3em;
          margin-right: 2px;
        }
        > .centric-heading {
          margin-bottom: 0;
          > svg {
            font-size: 1.333em;
            vertical-align: -0.2em;
            margin-right: 2px;
          }
        }
      }
      &__label {
        margin-bottom: 0;
        color: rgba(0, 0, 0, 0.85);
        &__key {
          font-weight: 500;
          margin-right: 4px;
          .screen-xs & {
            width: 100%;
            display: inline-block;
          }
        }
        > span.ant-typography {
          font-weight: normal;
          .ant-typography-copy {
            margin-left: 2px;
          }
        }
        .screen-xs & {
          font-size: 12px;
        }
        .screen-sm & {
          font-size: 13px;
        }
        .screen-md & {
          font-size: 15px;
        }
      }
    }
    &__divider.ant-divider {
      margin: 0.8571em 0 1.1428em;
      .screen-xs & {
        font-size: 10px;
      }
    }
    &__content {
      .ant-statistic-title {
        margin-bottom: 0;
      }
    }
  }
  &__content {
    margin: 1em;
    font-size: 16px;
  }
}