.content-container {
  padding-left: 0;
  min-height: 100vh;
  .screen-md & {
    padding-left: 250px;
  }
  .ant-btn {
    border-radius: 4px;
    border: none;
  }
}

h1.centric-heading {
  margin-bottom: 0.5em;
  line-height: 1.35;
  font-weight: 500;
  .screen-xs & {
    font-size: 20px;
  }
  .screen-sm & {
    font-size: 24px;
  }
  .screen-md & {
    font-size: 26px;
  }
}

h3.centric-heading {
  margin-bottom: 0.666em;
  line-height: 1.35;
  font-weight: 500;
  .screen-xs & {
    font-size: 18px;
  }
  .screen-sm & {
    font-size: 20px;
  }
  .screen-md & {
    font-size: 22px;
  }
}

h4.centric-heading {
  font-size: .867em;
  font-weight: 700;
  color: rgba(0,0,0,.85);
}

.external-link-icon {
  color: rgba(0,0,0,0.4) !important;
  font-size: 0.75em;
}
